import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Center, Heading, HStack, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";

function ContactUs() {
  return (
    <Layout>
      <Seo
        title="LET'S TALK ABOUT WHAT WE CAN BUILD TOGETHER | CONTACT US"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies, best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "We understand how influential websites can be for a business, thus we focus on developing websites that create a real impact. Our team is here to turn your business idea into a reality. Let's talk about what we can build together.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/contact_us_header.png"
          alt=" Bitdecoders Contact-us banner img"
          layout="fullWidth"
        />

        <Center
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <Heading
            as="h1"
            fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
            fontWeight="bold"
            p={{ base: "4", md: "6", lg: "8" }}
          >
            <HStack>
              <Text color="white">CONTACT</Text>
              <Text color="red.500"> US</Text>
            </HStack>
          </Heading>
        </Center>
      </Box>

      <Container fluid>
        {/* ADDRESS AND CONTACT FORM SECTION  */}

        <div className="grid grid-cols-1 mx-2 md:grid-cols-2">
          <div className="ml-12 font-bold">
            <div className="text-3xl mt-10">
              <span className="text-black space-x-1 font-bold">WHY </span>
              <span className="text-red-500 font-bold">CHOOSE </span>
              <span className="text-black font-bold">US</span>
            </div>
            <div className="text-left mt-4">
              <p>
                We understand how influential websites can be for a business,
              </p>
              <p> thus we focus on developing websites that create a real</p>
              <p> impact. Our team is here to turn your business idea into a</p>
              <p> reality.</p>
            </div>
            <div className="space-y-1 mt-10">
              <div>
                <StaticImage
                  src="../images/contact_mail.png"
                  alt="Bitdecoders contact-mail icon"
                />{" "}
                <i className="px-1">info@wellsoul.in</i>
              </div>

              <div>
                <StaticImage
                  src="../images/contact_call.png"
                  alt="Bitdecoders contact phone-no icon"
                />
                <i className="px-1">+91 9907864930</i>
              </div>
            </div>
            <div className="text-left text-3xl space-y-3 pt-14">
              <span className="text-black">Office </span>
              <span className="text-red-500">Address</span>
            </div>
            <div className="pt-10 flex items-center space-x-2">
              <StaticImage
                src="../images/contact_address.png"
                alt="Bitdecoders contact-address icon"
              />
              <h4>
                <span>Plot No. 539, Shalimar Garden Extension-1,</span>
                <p> Sahibabad, Ghaziabad, Uttar Pradesh-201005</p>
              </h4>
            </div>
          </div>
          <div className="mt-8 md:-top-36 md:w-3/4 relative">
            <ContactForm />
          </div>
        </div>

        {/* CLIENT FEEDBACK SECTION  */}

        <div className="font-bold my-8 ">
          <div className="text-center">
            <span className="text-black text-2xl">WHAT CLIENTS FEEL </span>
            <span className="text-red-500 text-2xl">ABOUT US?</span>
          </div>
          <div className="text-center pt-3">
            <p>
              Our quality cannot be defined in our words rather it can be seen
              in{" "}
            </p>
            <p>
              our <span className="text-red-500">client&apos;s experience</span>
            </p>
          </div>

          {/* SLIDE SECTION  */}

          <div>
            <h2 className="text-black font-bold space-y-2 text-center pt-5">
              BITDECODERS
            </h2>
            <div className="">
              <p className="text-black pt-2   font-bold space-y-2 text-center">
                CTO - Pijush Barik
              </p>
            </div>
            <div>
              <p className="text-center pt-4 ">
                Great place to work. Amazing work environment. I visited there
                for a fashion shoot for rkgphotopedia. It is located in Sector
                45{" "}
              </p>
              <p className="text-center">
                {" "}
                gurgaon. This four floor building is an exciting place to be and
                meet innovative people. The people working here are very
                co-operating.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default ContactUs;
